.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1004;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1% 0.5% 1.5% 1.5%;
    width: 100%;
    max-width: 42rem;
    display: flex;
    flex-direction: column;
}
  
  .category-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 9999px;
  }
  
  .close-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 0.5rem;
  }
  
  .section-title {
    color: #4b5563;
    margin-bottom: 0.95rem;
    margin-top: 15px;
  }
  .category-section{
    margin-bottom: 3%;
  }
  .category-options {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
  .category-option, .subcategory-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .subcategory-option {
    margin-bottom: 5%;
  }
  .category-column{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .checkbox {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  
  .subcategory-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;  
    width: 95%;
    margin: 0 auto;
  }
  
  .subcategory-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .subcategory-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #4b5563;
  }
  
  .subcategory-scroll {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 0.5rem;
  }
  
  .subcategory-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .subcategory-icon-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .submit-button {
    margin-top: 1.5rem;
    width: 35%;
    background-color: rgb(214, 25, 25);
    color: white;
    padding: 0.75rem 0;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    margin-left: auto;
    margin-right: 3%;
    margin-top: 2.5%; 
  }
  
  .submit-button:hover {
    background-color: #dc2626;
  }
  
  .subcategory-scroll::-webkit-scrollbar {
    width: 5px;
  }
  
  .subcategory-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .subcategory-scroll::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 4px;
  }
  
  .subcategory-scroll::-webkit-scrollbar-thumb:hover {
    background: #777;
  }

  @media (max-width: 480px) {
  .modal-content{
    padding: 20px 20px;
  }

  .category-column{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .category-option{
    display: flex;
    flex-direction: row;
  }
}