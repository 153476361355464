.filter-container {
  height: 9vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0% 1%;
  margin: 0%;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.country-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1003;
  overflow-y: auto;
  transform: translateY(-100%);
  animation: slideDown 0.5s ease-in forwards;
}

.country-modal {
  padding: 15px 10px;
  height: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  position: absolute;
  top: 5px;
  right: 10px;
}
.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  padding: 5px;
}

.country-grid {
  display: flex;
  flex-direction: column;
  gap: 1%;
}

.view-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.map-modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}
.modal-content-below {
  width: 100%;
  background: white;
  border-top: 1px solid #eaeaea;
  padding: 1rem;
}

.map-modal-content {
  padding: 20px 10px;
}
.modal-content-below {
  width: 100%;
  background: white;
  border-top: 1px solid #eaeaea;
  padding: 1rem;
}

.modal-content-inline {
  width: 100%;
  background: white;
  border-top: 1px solid #eaeaea;
}

.map-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.country-map-card {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.country-map-card:hover {
  transform: scale(1.02);
}

.map-preview {
  position: relative;
  width: 100%;
  height: 180px;
  background: #f1f1f1;
  overflow: hidden;
}

.map-preview img{
  width: 100%;
  height: 100%;
}

.country-label {
  position: absolute;
  top: 16px;
  left: 16px;
  background: #2563eb;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.view-toggle-button .lucide-compass {
  transition: transform 0.2s ease;
}

.view-toggle-button:hover .lucide-compass {
  transform: rotate(20deg);
}
  .desktop-view{
    width: 100%;
    display: grid;
    grid-template-columns: 77% 23%;
    height: 100%;
}

  .filter-container .country-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5%;
  }

  .country-div .map-img{
    height: 200px;
    width: 200px;
  }
  .country-div{
    margin-bottom: 30px;
  }

  .country-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0%;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

  }

  .country-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .country-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .country-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  .filter-container .date-display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 5%;
  }

  .filter-container{
    font-weight: bold;
    color: #404040;
    padding-left: 16px;
  }
  
  .category-desktop-filter-button{
    background-color: rgb(214, 25, 25);
    border: none;
    border-radius: 5px;
    font-size: medium;
    color: white;
    padding: 4% 6%;
    cursor: pointer;
  }

  .filter-country-button.active {
    background-color: rgb(214, 25, 25);
    color: white;
    font-weight: lighter;
    font-size: medium;
    cursor: pointer;
    padding: 3% 10%;
    border-radius: 5px;
    border: none;
  }

  .filter-country-button.inactive {
    background: none;
    color: #404040;
    border: none;
    font-weight: bold;
    font-size: medium;
    cursor: pointer;
  }

  .country-selected-indicator {
    color: rgb(0, 0, 0);
    font-weight: bold;
  }

  .country-button , .date-display-p{
    color: #676666;
  }

  
.filter-container  .logo{
    height: 49px;
    width: auto;
}

.mobile-view-toggle {
  display: flex;
  gap: 40px;
  padding: 8px;
  background: none;
  border-radius: 8px;
}

.view-toggle-button {
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 8px;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  font-weight: lighter;
  position: relative; 
}


.view-toggle-button.active {
  color: rgb(214, 25, 25);
  background: none;
}

.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
}

.filter-modal {
  background-color: white;
  padding: 1%;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  position: relative;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.region-button-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.mr-2{
  color: #272727;
}

.country-modal-container-outer{
  height: 100%;
  width: 100%;
}
.country-modal-container{
  width: 100%;
}

.quick-access-region-button{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100%;
  gap: 2%;
  width: 100%;
}

.region-button{
  color: #272727;
  padding: 5px 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: none;
  border: none;
  font-size: medium;
  border-radius: 5px;
}

.filter-modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
}

.filter-modal-close:hover {
  color: rgb(214, 25, 25);
}

.filter-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.filter-modal-content h2 {
  font-weight: lighter;
  padding-left: 10px;
  font-size: x-large;
}

.country-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.country-item img {
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}

.country-modal-container{
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.quick-access-countries {
  display: flex;
  gap: 12px;
  height: 100%;
}

.quick-access-country {
  display: flex;  
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  height: 100%;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: none;
  color: #676666;
  font-size: medium;
  font-weight: bold;
  border-bottom: 3px solid transparent; 
}

.quick-access-country.active {
  background: none;
  border-bottom: 3px solid rgb(214, 25, 25);
  color: rgb(214, 25, 25);;
}

/* Your existing styles */
.quick-access-country[data-region="delhi"][data-selected="true"] {
  border-bottom: 3px solid rgb(214, 25, 25);
  color: rgb(214, 25, 25);
}

.quick-access-country.active {
  background: none;
  border-bottom: 3px solid rgb(214, 25, 25);
}
.quick-access-flag {
  width: 24px;
  height: 16px;
  object-fit: cover;
  border-radius: 2px;
}

.quick-access-country span {
  font-size: 14px;
  color: #2d3748;
}

.mobile-country-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow-y: auto;
  padding: 16px 0;
}

.mobile-country-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
}

.mobile-country-flag {
  width: 40px;
  height: 28px;
  object-fit: cover;
  border-radius: 4px;
}

.mobile-country-button {
  flex: 1;
  text-align: left;
  padding: 8px;
  border: none;
  background: none;
  font-size: 16px;
  color: #404040;
  cursor: pointer;
}

.filter-modal.mobile {
  height: 80vh;
  margin: 10vh 0;
}

.mobile-country-button.active {
  color: rgb(214, 25, 25);
  font-weight: bold;
}

@media (max-width: 480px) {
.filter-container{
  height: 7vh;
}

.filter-container .logo{
  height: 40px;
  width: auto;
}

.nav-mobile-view {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 10px;
}

.mobile-view-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-left: 0;
  padding: 0;
  background: none;
}

.mobile-view-toggle {
  display: contents; /* This makes the container's children become grid items */
}
.view-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.logo {
  justify-self: center;
}
.view-toggle-button svg {
  width: 20px;
  height: 20px;
}
.view-toggle-button.active {
  color: rgb(214, 25, 25);
  border-bottom: 3px solid rgb(214, 25, 25);
}

.filter-container .country-buttons {
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 5px;
    gap: 10px;
  }

  .filter-container .date-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 100%;
  }

  .category-button {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .category-button img {
    width: 20px;
    height: 20px;
  }

  .filter-modal.mobile {
    width: 95%;
    max-height: 80vh;
  }
  
  .mobile-country-list {
    max-height: calc(80vh - 100px);
  }
  .map-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .country-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
}