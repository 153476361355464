.privacy-container {
    max-width: 64rem;
    margin: 0 auto;
    padding: 1rem;
    font-family: 'Museo Sans';
    line-height: 30px;
}

.privacy-policy-card {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
}

.main-title {
    font-size: 1.7rem;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 0.5rem;
}

.effective-date {
    font-size: 0.9rem;
    color: #6b7280;
    text-align: center;
    margin-bottom: 1rem;
}

.policy-section {
    margin-bottom: 2rem;
}

.privacy-section-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.subsection-title {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.policy-list {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact-link {
    color: #2563eb;
    text-decoration: none;
}

.contact-link:hover {
    text-decoration: underline;
}