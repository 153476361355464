.mobile-view-content {
    height: 93vh;
    display: flex;
    flex-direction: column;
  }
  
  .mobile-view-header {
    padding: 10px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .mobile-view-header h2 {
    font-size: 20px;
  }
  
  .mobile-categories-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
  }

  .category-section {
    margin-bottom: 1.5rem;
  }
  
  .category-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
  
  .category-item {
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: rgb(125, 125, 125);
  }
  
  .category-item.selected {
    color: black;
    background-color: #e1e0e0;
  }
  
  .category-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .category-icon {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
  
  .category-label {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
  
  .subcategories-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 1rem 0;
  }
  
  .mobile-view-footer {
    padding: 1rem;
    border-top: 1px solid #e5e7eb;
    background-color: white;
  }
  
  .apply-filters-button {
    width: 100%;
    padding: 0.75rem;
    background-color:  #dc3545;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  /* Ensure smooth transition for all interactive elements */
  .category-item, .apply-filters-button {
    transition: all 0.2s ease-in-out;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .select-all-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #f3f4f6;
    border: 1px solid #e5e7eb;
  }
  
  .select-all-button.selected {
    background-color:  #dc3545;
    color: white;
    border-color:  #dc3545;
  }

  /* Update subcategories-title margin */
  .subcategories-title {
    margin: 0;  /* Remove default margin since it's now in a flex container */
  }