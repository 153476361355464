.region-mobile-view-content {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .region-mobile-view-header {
    margin-bottom: 20px;
  }
  
  .region-mobile-view-header h2 {
    font-size: 20px;
    font-weight: 600;
  }
  
  .region-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  }
  
  .country-card {
    width: 100%;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .country-card:active {
    transform: scale(0.98);
  }
  
  .country-card.active {
    box-shadow: 0 4px 15px rgba(37, 99, 235, 0.2);
    border: 2px solid rgb(214, 25, 25);
  }
  
  .map-preview {
    position: relative;
    width: 100%;
    aspect-ratio: 3/2;
  }
  
  .map-preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .country-name-label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.95);
    color: rgb(79, 79, 79);
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: all 0.3s ease;
  }
  
  .country-name-label.active {
    font-size: 16px;
    color: white;
    background-color: rgb(214, 25, 25);
  }
  