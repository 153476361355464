* {
  font-family: 'Museo Sans', sans-serif;
  -webkit-overflow-scrolling: touch; 
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  padding: 0px;
  margin: 0%;
  box-sizing: border-box;
}

.map-sidebar.mobile-view {
  position: relative;
  height: 93vh;
  overflow: hidden;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.215);
}

.map-container-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-out;
  display: none;
}

.map-sidebar.mobile-view[data-view="map"] .map-container-mobile {
  display: block;
}

.sidebar-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-out;
  background-color: white;
  display: none;
}

.map-sidebar.mobile-view[data-view="sidebar"] .sidebar-mobile {
  display: block;
}

.map-sidebar.mobile-view[data-view="sidebar"] .map-container-mobile {
  transform: translateY(-100%);
}

.map-sidebar.mobile-view[data-view="sidebar"] .sidebar-mobile {
  transform: translateY(0);
}

.map-sidebar.mobile-view[data-view="map"] .map-container-mobile {
  transform: translateY(0);
}

.map-sidebar.mobile-view[data-view="map"] .sidebar-mobile {
  transform: translateY(100%);
}

.region-view-mobile{
  overflow-y: scroll;
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh; 
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  padding: 0px;
  margin: 0%;
  box-sizing: border-box;
}


.leaflet-container {
  width: 100%;
  height: 100%;
}

.location-error-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FEF3C7;
  color: #92400E;
  padding: 0.75rem;
  text-align: center;
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-error-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  font-size: 1.2em;
  cursor: pointer;
  padding: 0 10px;
}

.close-error-button:hover {
  opacity: 0.7;
}

.location-action-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.location-action-button:hover {
  background-color: #c82333;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.map-container {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: column;
  min-width: 0; 
  gap: 1%;
}

.custom-marker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
}

.map-sidebar {
  display: flex;
  flex-direction: row;
  height:91vh; 
  overflow: hidden;
  position: relative;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.25rem;
  color: #4B5563;
}

.map-guide {
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 1000;
}
.minimap-rectangle{
  border: 1px solid black;
}
.mini-map {
  position: relative;
  width: 128px;
  height: 128px;
  border: 1px solid #e0e0e0;
  margin-bottom: 8px;
}

.mini-map-bg {
  position: absolute;
  inset: 0;
  background: #f0f7ff;
}

.highlight-box {
  position: absolute;
  width: 48px;
  height: 48px;
  border: 2px solid #f97316;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scale-indicators {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
}

.scale-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.scale-line {
  height: 4px;
  margin-bottom: 4px;
  background: #666;
}

.scale-line.km {
  width: 32px;
}

.scale-line.mi {
  width: 24px;
}

.coordinates {
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  color: #666;
}






@media (max-width: 540px) {
  .app-container {
    flex-direction: column;
    overflow: hidden;
  }

  .map-sidebar {
    flex-direction: column;
    height: 92vh;
  }

  .map-container {
    width: 100%;
    height: 45vh;
  }

  .location-error-banner {
    font-size: 12px;
    padding: 0.5rem;
  }

  .loading-container {
    font-size: 1rem;
  }
} 